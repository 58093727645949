import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const ImageGallery = makeShortcode("ImageGallery");
const ImageGalleryImage = makeShortcode("ImageGalleryImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
    </PageDescription>
    <ImageGallery mdxType="ImageGallery">
      <ImageGalleryImage alt="magnuscode" title="Magnus Code Render Main Title" col={8} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1000px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHhy5wFf//EABkQAAIDAQAAAAAAAAAAAAAAAAEQABIhMf/aAAgBAQABBQKhExHi/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAARARQf/aAAgBAQAGPwIXWH//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEQIVGR/9oACAEBAAE/IUC1BZKHWMfhn//aAAwDAQACAAMAAAAQnw//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxBn/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QJ//EABwQAQACAQUAAAAAAAAAAAAAAAEAESEQMVFhgf/aAAgBAQABPxB3i6N7PI8o7xKXgoiNqtYR0//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9e6afac7e31e0a69118ec6ff51b3e458/5f1c3/magnus_render.webp 288w", "/static/9e6afac7e31e0a69118ec6ff51b3e458/c4eb6/magnus_render.webp 576w", "/static/9e6afac7e31e0a69118ec6ff51b3e458/e00f7/magnus_render.webp 1000w"],
              "sizes": "(max-width: 1000px) 100vw, 1000px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9e6afac7e31e0a69118ec6ff51b3e458/58ca1/magnus_render.jpg 288w", "/static/9e6afac7e31e0a69118ec6ff51b3e458/7fee0/magnus_render.jpg 576w", "/static/9e6afac7e31e0a69118ec6ff51b3e458/16038/magnus_render.jpg 1000w"],
              "sizes": "(max-width: 1000px) 100vw, 1000px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9e6afac7e31e0a69118ec6ff51b3e458/16038/magnus_render.jpg",
              "alt": "Product Visualization and 3d rendering",
              "title": "Product Visualization and 3d rendering",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="plc" title="Siemens PLC" col={4} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIEBQED/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAIDAf/aAAwDAQACEAMQAAAB16+RJkuunzVkSp5gP//EABwQAAICAgMAAAAAAAAAAAAAAAECAAMQEhMhMf/aAAgBAQABBQIWJD4E61LNx2haQRVqBgopn//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAECAQE/AWP/xAAdEAACAQQDAAAAAAAAAAAAAAAAATECEBIyESGR/9oACAEBAAY/AtqTYQuFBjjIk5IVu6V4f//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQYFRYXH/2gAIAQEAAT8hsbu4zAC/YeriJy5BYy635YIWNCCrA6lHiJWl9x//2gAMAwEAAgADAAAAEO/oPP/EABcRAQADAAAAAAAAAAAAAAAAAAEAECH/2gAIAQMBAT8QRZt//8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAEP/aAAgBAgEBPxAhwk7/AP/EABwQAQADAAMBAQAAAAAAAAAAAAEAESExQWFRkf/aAAgBAQABPxBNTFdBupbsKtUuJuF+J8/YRnMSDah05O5oKAi6axgseUBc8H5L312Cz//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5158e3420f3de04aa6e88e0af942d690/5f1c3/plc.webp 288w", "/static/5158e3420f3de04aa6e88e0af942d690/10636/plc.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5158e3420f3de04aa6e88e0af942d690/58ca1/plc.jpg 288w", "/static/5158e3420f3de04aa6e88e0af942d690/10bbb/plc.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/5158e3420f3de04aa6e88e0af942d690/10bbb/plc.jpg",
              "alt": "Automation and IoT",
              "title": "Automation and IoT",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="magnussquare" title="Magnus square title" col={4} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAEDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAAByc7VkEgllYUBv//EABoQAAICAwAAAAAAAAAAAAAAAAARARICFCD/2gAIAQEAAQUC18aqBF5XP//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABURAQEAAAAAAAAAAAAAAAAAAAEg/9oACAECAQE/ASP/xAAYEAEAAwEAAAAAAAAAAAAAAAABEBEgMf/aAAgBAQAGPwJb5Lr/xAAbEAACAgMBAAAAAAAAAAAAAAAAAREhEDFBkf/aAAgBAQABPyFvSag0OL9IvrKKdjdknB4//9oADAMBAAIAAwAAABATz/z/xAAWEQEBAQAAAAAAAAAAAAAAAAABERD/2gAIAQMBAT8QZCY5/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQEQ/9oACAECAQE/EE1yZ//EABwQAAMAAgMBAAAAAAAAAAAAAAABESExEEFR0f/aAAgBAQABPxBMW7dQdUWLlj4LKUDeCSR52MbFeldZNuP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a04eea31c82acf8c5c5613bbd024964d/5f1c3/magnus_square.webp 288w", "/static/a04eea31c82acf8c5c5613bbd024964d/10636/magnus_square.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a04eea31c82acf8c5c5613bbd024964d/58ca1/magnus_square.jpg 288w", "/static/a04eea31c82acf8c5c5613bbd024964d/10bbb/magnus_square.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a04eea31c82acf8c5c5613bbd024964d/10bbb/magnus_square.jpg",
              "alt": "3d rendering and animation",
              "title": "3d rendering and animation",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="gcpbase" title="Gong center post base" col={4} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIE/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB03nFoCwgH//EABkQAAMBAQEAAAAAAAAAAAAAAAABEQITEv/aAAgBAQABBQJZEho6RdGj3ktKOH//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAACAgMAAAAAAAAAAAAAAAAAMQEQAhEh/9oACAEBAAY/ArUncZ0O0f/EABsQAQEBAAIDAAAAAAAAAAAAAAERACFxYYGR/9oACAEBAAE/ISmGPeK6uJvM41XaJlGz71COHimo+b//2gAMAwEAAgADAAAAELPHAP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABsQAQADAQADAAAAAAAAAAAAAAEAESExUXGB/9oACAEBAAE/EGjCawOoLJLAcGnAfYmImqBUuVoNnFTAmlBuoXlHoitF8on/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f7d7224c48e88808676fe7226c377e84/5f1c3/gcp_base.webp 288w", "/static/f7d7224c48e88808676fe7226c377e84/10636/gcp_base.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f7d7224c48e88808676fe7226c377e84/58ca1/gcp_base.jpg 288w", "/static/f7d7224c48e88808676fe7226c377e84/10bbb/gcp_base.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f7d7224c48e88808676fe7226c377e84/10bbb/gcp_base.jpg",
              "alt": "Mechanical design and manufacturing",
              "title": "Mechanical design and manufacturing",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="Product design malaysia" title="Bell and Ross rendering" col={4} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUEA//EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGHRmaByhOlEipQuf/EABwQAAEEAwEAAAAAAAAAAAAAAAIAAQMEERIzMv/aAAgBAQABBQKN8HYPeJD6J31UfSzyX//EABYRAQEBAAAAAAAAAAAAAAAAABABMf/aAAgBAwEBPwEmH//EABcRAAMBAAAAAAAAAAAAAAAAAAEQETH/2gAIAQIBAT8BiOhf/8QAGxAAAQUBAQAAAAAAAAAAAAAAAQAQESFxAlH/2gAIAQEABj8CBUX69y3Ooa3/xAAdEAACAgIDAQAAAAAAAAAAAAABEQAQITFBUWGh/9oACAEBAAE/IeRkXFi4HajQIOa8nZFfAmPpT//aAAwDAQACAAMAAAAQoAhA/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARMf/aAAgBAwEBPxAEyLRf/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQIBAT8QQoxv/8QAHRABAQABBAMAAAAAAAAAAAAAAREAECFRkTFB8P/aAAgBAQABPxCnEHHMxUtpDJ6TbvRE0XYluUBC4YPGiRD4cUJ4C9On/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3adc0057be01c7159c95be0069eb1ac3/5f1c3/bell-ross.webp 288w", "/static/3adc0057be01c7159c95be0069eb1ac3/10636/bell-ross.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3adc0057be01c7159c95be0069eb1ac3/58ca1/bell-ross.jpg 288w", "/static/3adc0057be01c7159c95be0069eb1ac3/10bbb/bell-ross.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/3adc0057be01c7159c95be0069eb1ac3/10bbb/bell-ross.jpg",
              "alt": "Product design and rendering",
              "title": "Product design and rendering",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="Leica M9" title="Leica M9" col={4} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAMEAQIF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAeZrBKTsCtaC6LP/xAAbEAACAgMBAAAAAAAAAAAAAAAAAQMRAhIhEP/aAAgBAQABBQJzNm+TO15YiPi1s//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABwQAAICAgMAAAAAAAAAAAAAAAABEBEhMTJBcf/aAAgBAQAGPwLpeGGc4pGoTLdn/8QAGhABAQEBAQEBAAAAAAAAAAAAAREAITFBYf/aAAgBAQABPyF+MPsYsgTDJUXBWH3QGI/uBOjLnhb7gQRe+7iJSb//2gAMAwEAAgADAAAAEGQoAf/EABcRAAMBAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQMBAT8QE1Ff/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFBUXGh/9oACAEBAAE/EAw2O4kAXJLAWMRQuhIh7lUMSnb586IQCE5IBT7AmzEEyGtEokhOGg8Cf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/04dca61896506aa0e7bc0fc1ea756d98/5f1c3/leica.webp 288w", "/static/04dca61896506aa0e7bc0fc1ea756d98/10636/leica.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/04dca61896506aa0e7bc0fc1ea756d98/58ca1/leica.jpg 288w", "/static/04dca61896506aa0e7bc0fc1ea756d98/10bbb/leica.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/04dca61896506aa0e7bc0fc1ea756d98/10bbb/leica.jpg",
              "alt": "Product design and rendering Malaysia. Magnus Code",
              "title": "Product design and rendering Malaysia. Magnus Code",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
    </ImageGallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      